<template>
    <div :class="['evaluation-details', {current: first === true}]">
        <span>{{ title }}</span>
        <span>{{ starText }}</span>
    </div>
</template>
<script>
export default {
    name: "EvaluationDetails",
    props: {
        title: {
            type: String
        },
        star: {
            type: Number
        },
        first: {
            type: Boolean
        }
    },
    computed: {
        starText () {
            let starNum = Number(this.star);
            switch(starNum) {
                case 0:
                    return '非常满意'
                case 1:
                    return '满意'
                case 2:
                    return '一般'
                case 3:
                    return '不满意'
                case 4:
                    return '非常不满意'
                default:
                    return '非常满意'
            }
        }
    }
}
</script>
<style lang="scss">
    .evaluation-details {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 29px;
        &.current {
            margin-top: 4px;
        }

        span {
            padding-right: 10px;
        }
    }
</style>

<template>
    <img :src="defaultIcon" alt="close" class="close-icon" @click="close">
</template>
<script>
export default {
    name: "CloseGroup",
    computed: {
        defaultIcon () {
            return require('../img/close.png')
        }
    },
    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .close-icon {
        width: 13px;
        height: 13px;
        display: block;
        cursor: pointer;
    }
</style>

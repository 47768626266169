<template>
    <div class="select-group">
        <h3 class="title">{{ title }}</h3>
        <el-cascader
            v-model="value"
            :placeholder="placeholder"
            :options="options"
            :props="{ multiple: false }"
            clearable
            filterable
            @change="changeSelectModule"
        >
        </el-cascader>
    </div>
</template>
<script>
export default {
    name: "SelectModuleOption",
    props: {
        title: {
            type: String,
            default: () => {
                return '反馈对象'
            }
        },
        options: {
            type: Array,
            default: () => {
                return []
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return '您在使用哪个功能模块遇到了问题'
            }
        },
        pageIds: {
            type: Array
        }
    },
    data () {
        return {
            value: [],
            restaurants: []
        }
    },
    mounted () {
        this.value = this.pageIds;
        this.restaurants = this.options;
        if (this.$route.name === 'firstPageHome' || this.$route.name === 'FirstPage') {
            this.value = ['000000', '000100'];
            this.changeSelectModule(this.value);
        };
    },
    methods: {
        changeSelectModule (ev) {
            this.$emit('changeSelectModule', ev)
        }
    },
    watch: {
        value: {
            handler (newVal) {
                console.log(newVal,'newVal-newVal')
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.select-group {
    padding: 10px 26px 9px 20px;
    .title {
        padding: 6px 0 7px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
    }
    /deep/ .el-cascader {
        width: 100%;
        height: 100%;
        input::placeholder {
            font-family: "微软雅黑", sans-serif;
        }
        input {
            width: 394px;
            height: 36px;
            &:not(:placeholder-shown) {
                background-color: #FFFFFF;
                border:1px solid #D7DBE0;
            }
        }
        input:focus {
            background: #FFFFFF;
        }
    }
}
</style>

<template>
    <div class="contacts-group">
        <h3 class="title">{{ title }}</h3>
        <el-input
            v-model="contacts"
            :placeholder="placeholder"
            @input="change"
        >
        </el-input>
    </div>
</template>
<script>
export default {
    name: "ContactGroup",
    props: {
        title: {
            type: String,
            default: () => {
                return '联系方式'
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return '您的联系方式'
            }
        }
    },
    data () {
        return {
            contacts: ''
        }
    },
    methods: {
        change () {
            this.$emit('changeContacts', this.contacts)
            console.log(this.contacts,'contacts')
        }
    }
}
</script>
<style lang="scss" scoped>
    .contacts-group {
        padding: 20px 26px 0px 20px;
        .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            padding-bottom: 13px;
        }
        /deep/ .el-input {
            width: 100%;
            height: 100%;
            input::placeholder {
                font-family: "微软雅黑", sans-serif;
            }
            input {
                width: 394px;
                height: 36px;
                &:not(:placeholder-shown) {
                    background-color: #FFFFFF;
                    border:1px solid #D7DBE0;
                }
            }
            input:focus {
                background: #FFFFFF;
            }
        }
    }
</style>

<template>
    <div class="detail-group">
        <h3 :class="['detail-title', { current: mt === true}]">{{ title }}</h3>
        <p v-if="detailText" class="element">{{ detailText }}</p>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "DetailGroup",
    props: {
        title: {
            type: String
        },
        detailText: {
            type: String
        },
        mt: {
            type: Boolean
        }
    }
}
</script>
<style lang="scss" scoped>
    .detail-title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        &.current {
            // margin-top: 21px;
        }
    }
    .element {
        box-sizing: border-box;
        padding: 8px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        background: #F6F6F6;
        border-radius: 6px;
        word-break: break-word;
    }

</style>
